import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { AuthenticationContext } from "../../../providers/AuthenticationProvider/AuthenticationProvider";
import {
  IonContent,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import SubscriptionsContent from "./sub/SubscriptionsContent";
import AnagraphicContent from "./sub/AnagraphicContent";

const UserManagement = ({ match }) => {
  const { appUserId } = useContext(AuthenticationContext);
  const [currentTab, setCurrentTab] = useState("anagrafica");
  const userId = match.params.userId;
  return (
    <IonPage>
      <IonContent id="main-content">
        <IonSegment
          value={currentTab}
          scrollable
          onIonChange={(evt) => setCurrentTab(evt.detail.value)}
        >
          <IonSegmentButton value="anagrafica">
            <IonLabel>Anagrafica</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="abbonamenti">
            <IonLabel>Abbonamenti</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        {/* currentTab: {currentTab} */}
        {currentTab === "anagrafica" && <AnagraphicContent userId={userId} />}
        {currentTab === "abbonamenti" && (
          <SubscriptionsContent
            coachId={appUserId}
            userId={userId}
            defaultFilterValue="Tutti"
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default UserManagement;

UserManagement.propTypes = {
  match: PropTypes.object.isRequired,
};
