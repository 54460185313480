import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

function SubscriptionsModal({
  hideSelectedSubscriptions,
  isOpen,
  subscriptions,
  selectedSubscriptions,
  onDismiss,
  onConfirm,
}) {
  const [temporarySelectedSubscriptions, setTemporarySelectedSubscriptions] =
    useState([]);

  useEffect(() => {
    if (isOpen) {
      setTemporarySelectedSubscriptions(selectedSubscriptions);
    }
  }, [isOpen]);

  const onSelectionChange = (e, subscription) => {
    const subscriptionId = subscription.id;
    if (e.detail.checked) {
      const selectedSubscription = subscriptions.find(
        (subscription) => subscription.id === subscriptionId,
      );
      setTemporarySelectedSubscriptions([
        ...temporarySelectedSubscriptions,
        selectedSubscription,
      ]);
    } else {
      setTemporarySelectedSubscriptions(
        temporarySelectedSubscriptions.filter(
          (subscription) => subscription.id !== subscriptionId,
        ),
      );
    }
  };

  const onDismissModal = () => {
    onDismiss();
  };

  const onConfirmModal = () => {
    if (hideSelectedSubscriptions) {
      const selectedSubscriptionsIds = selectedSubscriptions.map((a) => a.id);
      const newSelectedSubscriptions = temporarySelectedSubscriptions.filter(
        (a) => !selectedSubscriptionsIds.includes(a.id),
      );
      onConfirm(newSelectedSubscriptions);
      return;
    }
    onConfirm(temporarySelectedSubscriptions);
  };

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismissModal}>Annulla</IonButton>
          </IonButtons>
          <IonTitle>Seleziona tariffe</IonTitle>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={onConfirmModal}>
              Conferma
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          {subscriptions &&
            subscriptions.map((subscription) => {
              const selected = temporarySelectedSubscriptions
                .map((a) => a.id)
                .includes(subscription.id);

              const isInSelected = selectedSubscriptions
                .map((a) => a.id)
                .includes(subscription.id);

              if (hideSelectedSubscriptions && isInSelected && selected) {
                return null;
              }
              return (
                <IonItem key={subscription.id}>
                  <IonCheckbox
                    labelPlacement="end"
                    justify="start"
                    checked={selected}
                    onIonChange={(e) => onSelectionChange(e, subscription)}
                  >
                    {`${subscription.name}`}
                  </IonCheckbox>
                </IonItem>
              );
            })}
        </IonList>
      </IonContent>
    </IonModal>
  );
}

SubscriptionsModal.propTypes = {
  hideSelectedSubscriptions: PropTypes.bool,
  isOpen: PropTypes.bool,
  subscriptions: PropTypes.array,
  selectedSubscriptions: PropTypes.array,
  onDismiss: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default SubscriptionsModal;
