import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonTitle,
  IonToolbar,
} from "@ionic/react";

function EditCoachUserModal({ user, isOpen, onWillDismiss, onConfirmModal }) {
  const [toDate, setToDate] = useState(null);
  const [toDateSubscription, setToDateSubscription] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalSubscription, setShowModalSubscription] = useState(false);

  const handleDateChange = (e) => {
    const dateWithoutTime = new Date(e.detail.value);
    dateWithoutTime.setHours(0, 0, 0, 0);
    setToDate(dateWithoutTime);
    setShowModal(false); // Chiudi il modal dopo la selezione
  };
  const handleDateSubscriptionChange = (e) => {
    const dateWithoutTime = new Date(e.detail.value);
    dateWithoutTime.setHours(0, 0, 0, 0);
    setToDateSubscription(dateWithoutTime); // Chiudi il modal dopo la selezione
    setShowModalSubscription(false);
  };

  useEffect(() => {
    if (user && user.certificate_expire) {
      setToDate(new Date(user.certificate_expire.seconds * 1000));
    }
    if (user && user.subscription_expire) {
      setToDateSubscription(new Date(user.subscription_expire.seconds * 1000));
    }
  }, []);

  return (
    <IonModal isOpen={isOpen} onWillDismiss={(ev) => onWillDismiss(ev)}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onWillDismiss}>Annulla</IonButton>
          </IonButtons>
          <IonTitle>Modifica allievo</IonTitle>
          <IonButtons slot="end">
            <IonButton
              strong={true}
              onClick={() => {
                onConfirmModal({
                  ...user,
                  certificate_expire: toDate,
                  subscription_expire: toDateSubscription,
                });
                onWillDismiss();
              }}
            >
              Conferma
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <h2 className="ion-text-center">
          {user && `${user.cognome} ${user.nome}`}
        </h2>
        <IonList>
          <IonItem onClick={() => setShowModal(true)}>
            <IonLabel>Scadenza certificato</IonLabel>
            <div>
              {/* Mostra la data selezionata o un trattino se non selezionata */}
              {toDate ? new Date(toDate).toLocaleDateString() : "-"}
            </div>

            {/* Modale per la selezione della data */}
          </IonItem>
          <IonItem onClick={() => setShowModalSubscription(true)}>
            <IonLabel>Scadenza iscrizione</IonLabel>
            <div>
              {/* Mostra la data selezionata o un trattino se non selezionata */}
              {toDateSubscription
                ? new Date(toDateSubscription).toLocaleDateString()
                : "-"}
            </div>

            {/* Modale per la selezione della data */}
          </IonItem>
        </IonList>
        <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
          <IonDatetime
            className="EditUserDatePicker"
            presentation="date"
            value={
              toDate ? new Date(toDate).toISOString() : new Date().toISOString()
            }
            onIonChange={handleDateChange}
          />
          <IonButton onClick={() => setShowModal(false)}>Chiudi</IonButton>
        </IonModal>
        <IonModal
          isOpen={showModalSubscription}
          onDidDismiss={() => setShowModalSubscription(false)}
        >
          <IonDatetime
            className="EditUserDatePicker"
            presentation="date"
            value={
              toDateSubscription
                ? new Date(toDateSubscription).toISOString()
                : new Date().toISOString()
            }
            onIonChange={handleDateSubscriptionChange}
          />
          <IonButton onClick={() => setShowModalSubscription(false)}>
            Chiudi
          </IonButton>
        </IonModal>
      </IonContent>
    </IonModal>
  );
}

EditCoachUserModal.propTypes = {
  user: PropTypes.object,
  isOpen: PropTypes.bool,
  onConfirmModal: PropTypes.func,
  onWillDismiss: PropTypes.func,
};

export default EditCoachUserModal;
