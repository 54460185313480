import React, { useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";

const addPreventingCache = (params) => ({
  preventCache: Date.now(),
  ...params,
});

/**
 * Hook, used for receiving actual data for passed params.
 */
function useDataProvider({
  url,
  headers: receivedHeaders,
  requestType,
  params: receivedParams,
  // To prevent call and maintain same data
  preventCall,
  dataModifierFn,
}) {
  const [data, setData] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const { apiBaseUrl } = useContext(FirebaseContext);

  const params = React.useMemo(
    () => addPreventingCache(receivedParams),
    [JSON.stringify(receivedParams)],
  );
  const headers = React.useMemo(
    () => receivedHeaders,
    [JSON.stringify(receivedHeaders)],
  );

  React.useEffect(() => {
    if (typeof url !== "string")
      setError(new TypeError("Url is not a string type"));
    else setError(null);
  }, [url]);

  React.useEffect(() => {
    let isMounted = true;
    setIsLoading(true);
    let request = null;
    // if url is relative, add apiBaseUrl
    let managedUrl = url;
    if (url && url[0] === "/") {
      managedUrl = `${apiBaseUrl}${url}`;
    }
    if (preventCall) {
      // eslint-disable-next-line no-undef
      request = window.Promise.resolve(null);
    } else if (requestType === "post") {
      request = axios.post(managedUrl, params, { headers });
    } else if (requestType === "put") {
      request = axios.put(managedUrl, { params });
    } else {
      request = axios.get(managedUrl, { params });
    }

    request
      .then((newData) => {
        if (!preventCall && isMounted) {
          setData(dataModifierFn ? dataModifierFn(newData) : newData);
          setError(null);
        }
      })
      .catch((err) => {
        if (!isMounted) return;
        setData(null);
        console.error(err);
        setError(true);
      })
      .then(() => {
        if (!isMounted) return;
        setIsLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, [dataModifierFn, headers, params, preventCall, requestType, url]);

  return React.useMemo(
    () => ({ data, isLoading, error }),
    [data, isLoading, error],
  );
}

useDataProvider.propTypes = {
  /** Endpoint to call to */
  url: PropTypes.string.isRequired,
  /** Type of the request */
  requestType: PropTypes.oneOf(["get", "post", "put"]),
  /** Params to pass into request */
  params: PropTypes.shape({}),
  /** Headers object to pass into request */
  headers: PropTypes.shape({}),
  /** Flag, while truthy - request will not be done,
   * to prevent call and provide same, old data */
  preventCall: PropTypes.bool,
  /**
   * Function to extract chunk of data from RestAPI response. Remember to wrap in useCallback
   * Example:
   *    React.useCallback((response) => response && response.data && response.data.content, []);
   */
  dataModifierFn: PropTypes.func,
};

useDataProvider.defaultProps = {
  requestType: "get",
  headers: null,
  dataModifierFn: null,
};

export default useDataProvider;
