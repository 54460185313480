import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FirebaseContext } from "../FirebaseProvider/FirebaseProvider";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import useUserAppData from "../../hooks/users/useUserAppData";
import { useHistory } from "react-router-dom";
import useUserSecureAppData from "../../hooks/users/useUserSecureAppData";
import { useIonToast } from "@ionic/react";
import { close } from "ionicons/icons";

// Create a new context for the authentication provider
export const AuthenticationContext = createContext({
  appUserId: null,
  user: null,
  userDetails: null,
  isCoach: false,
  isJanus: false,
  isLogged: false,
  loading: true,
  logOutUser: () => { },
});

// Create the authentication provider component
const AuthenticationProvider = ({ children }) => {
  const [present] = useIonToast();
  const [user, setUser] = useState(null);
  const [isLogged, setIsLogged] = useState(false);
  const [loading, setLoading] = useState(true);
  const { firebaseApp } = useContext(FirebaseContext);
  const { data: userDetails, isLoading } = useUserAppData(user?.uid);
  const { data: userSecureDetails, isSecureLoading } = useUserSecureAppData(
    user?.uid,
  );
  const [isRegistered, setIsRegistered] = useState();
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (initialized) {
      return;
    }
    console.log("App initialized", initialized);
    if (
      userDetails &&
      userDetails.certificate_expire &&
      userDetails.certificate_expire.seconds
    ) {
      setInitialized(true);
      if (
        userDetails.certificate_expire &&
        userDetails.certificate_expire.seconds
      ) {
        // if the user has a certificate expiration date, check if it's expired or if it's about to expire
        const certificateExpireDate = new Date(
          userDetails.certificate_expire.seconds * 1000,
        );
        const currentDate = new Date();
        const daysToExpire = Math.floor(
          (certificateExpireDate.getTime() - currentDate.getTime()) /
          (1000 * 60 * 60 * 24),
        );

        if (daysToExpire <= 30 && daysToExpire >= 0) {
          // if the certificate is about to expire, show a notification
          present({
            message: `Il tuo certificato medico scade tra ${daysToExpire} giorni`,
            color: "warning",
            position: "top",
            swipeGesture: true,
            buttons: [
              {
                icon: close,
              },
            ],
          });
        } else if (daysToExpire < 0) {
          // if the certificate is expired, show a notification
          present({
            message: "Il tuo certificato medico è scaduto",
            color: "danger",
            // duration: 5000,
            swipeGesture: true,
            position: "top",
            buttons: [
              {
                icon: close,
              },
            ],
          });
        }
      }
      if (
        userDetails.subscription_expire &&
        userDetails.subscription_expire.seconds
      ) {
        // if the user has a certificate expiration date, check if it's expired or if it's about to expire
        const certificateExpireDate = new Date(
          userDetails.subscription_expire.seconds * 1000,
        );
        const currentDate = new Date();
        const daysToExpire = Math.floor(
          (certificateExpireDate.getTime() - currentDate.getTime()) /
          (1000 * 60 * 60 * 24),
        );

        if (daysToExpire <= 30 && daysToExpire >= 0) {
          // if the certificate is about to expire, show a notification
          present({
            message: `La tua iscrizione scade tra ${daysToExpire} giorni`,
            color: "warning",
            position: "top",
            swipeGesture: true,
            buttons: [
              {
                icon: close,
              },
            ],
          });
        } else if (daysToExpire < 0) {
          // if the certificate is expired, show a notification
          present({
            message: "La tua iscrizione è scaduta",
            color: "danger",
            // duration: 5000,
            swipeGesture: true,
            position: "top",
            buttons: [
              {
                icon: close,
              },
            ],
          });
        }
      }
    }
  }, [initialized, userDetails]);

  useEffect(() => {
    // Get the current user
    const auth = getAuth(firebaseApp);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (isRegistered === undefined || isRegistered === true) {
        setUser(user);
        setIsLogged(!!user);
        setLoading(false);
      }

      if (!user) {
        setIsRegistered(false);
      } else if (user && isRegistered === undefined) {
        setIsRegistered(true);
        setUser(user);
        setIsLogged(!!user);
        setLoading(false);
      }
    });
    return unsubscribe;
  }, [firebaseApp, isRegistered]);

  const logOutUser = async () => {
    const auth = getAuth(firebaseApp);
    await auth.signOut();
    // clear all session storage and local storage
    window.sessionStorage.clear();
    window.localStorage.clear();
    // reload and redirect to home page
    window.location.href = "/";
  };

  const isCoach = userSecureDetails?.roles?.includes("coach");
  // check if array tags contains at leas one element of the array
  const isJanus = userDetails?.tags?.some((tag) =>
    ["SqkGqheA3XIP5A9V45XY", "4EOg0HN0bZT2BvXRDFAG"].includes(tag),
  );
  const appUserId = userDetails?.id;

  // Render the children only when the authentication state is loaded
  return (
    <AuthenticationContext.Provider
      value={{
        isLogged,
        isCoach,
        isJanus,
        user,
        appUserId,
        userDetails,
        loading,
        logOutUser,
      }}
    >
      {!loading && !isLoading && !isSecureLoading && children}
    </AuthenticationContext.Provider>
  );
};

AuthenticationProvider.displayName = "AuthenticationProvider";

AuthenticationProvider.propTypes = {
  children: PropTypes.node,
};

export default AuthenticationProvider;
