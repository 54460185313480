import { useContext, useEffect } from "react";
import useFirestore from "./basic/useFirestore";
import { doc, getDoc } from "firebase/firestore";
import { FirebaseContext } from "../providers/FirebaseProvider/FirebaseProvider";

function useAthleteByCoach(coachId, athleteId, preventCall = false) {
  const { firestoreDb } = useContext(FirebaseContext);

  const firestoreQuery = async () =>
    getDoc(doc(firestoreDb, "users", coachId, "athletes", athleteId));

  const parsingFuntion = (data) => {
    if (!data.exists()) {
      throw new Error("Athlete not found");
    }
    return { id: data.id, ...data.data() };
  };

  const { data, error, isLoading, refreshData } = useFirestore(
    firestoreQuery,
    parsingFuntion,
    preventCall,
  );

  useEffect(() => {
    refreshData();
  }, [coachId]);

  return {
    data,
    error,
    isLoading,
    refreshData,
  };
}

export default useAthleteByCoach;
