import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import {
  IonButton,
  IonCard,
  IonContent,
  IonDatetime,
  IonDatetimeButton,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonTitle,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import useUserGroupsByIdAndManager from "../../../hooks/groups/useUserGroupsByIdAndManager";
import { AuthenticationContext } from "../../../providers/AuthenticationProvider/AuthenticationProvider";
import useSaveOrUpdateBooking from "../../../hooks/bookings/useSaveOrUpdateBooking";
import useBookingsByClubAndFields from "../../../hooks/bookings/useBookingsByClubAndFields";
import Spinner from "../../../components/Spinner/Spinner";
import SchedulerCard from "../../../components/SchedulerCard/SchedulerCard";
import useAPI from "../../../hooks/basic/useAPI";

const fromDateDefault = new Date();
// create a variable toDate that contains the first day of the next month
const toDateDefault = new Date(fromDateDefault);
toDateDefault.setMonth(toDateDefault.getMonth() + 1);

function doPlanification(
  userId,
  fromDate,
  toDate,
  timeStart,
  timeStop,
  dayOfWeek,
  selectedAthletes,
  club,
  field,
  groupId,
  maxParticipants,
  tags,
  label,
  description,
  clubName,
  fieldName,
) {
  // save a new event in the calendar from fromDate to toDate using selectedAthletes every dayOfWeek at timeStart to timeStop
  let currentDate = new Date(fromDate);
  let endDate = new Date(toDate);

  // remove time from dates
  currentDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  const dateTimeStart = new Date(timeStart);
  // dateTimeStart.setHours(
  //   dateTimeStart.getHours() + dateTimeStart.getTimezoneOffset() / 60,
  // );
  const dateTimeStop = new Date(timeStop);
  // dateTimeStop.setHours(
  //   dateTimeStop.getHours() + dateTimeStop.getTimezoneOffset() / 60,
  // );

  const bookings = [];
  const remainingSlots = maxParticipants - selectedAthletes.length;

  while (currentDate <= endDate) {
    if (currentDate.getDay() === dayOfWeek) {
      const booking = {
        club,
        field,
        participantUserIds: selectedAthletes
          .filter((a) => a.userId)
          .map((a) => a.userId),
        participants: selectedAthletes,
        from: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          new Date(dateTimeStart).getHours(),
          new Date(dateTimeStart).getMinutes(),
        ),
        to: new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          new Date(dateTimeStop).getHours(),
          new Date(dateTimeStop).getMinutes(),
        ),
        relatedGroupId: groupId,
        relatedSupervisorId: userId,
        maxParticipants,
        remainingSlots,
        tags: tags.map((t) => t.id),
        label,
        description,
        clubName,
        fieldName,
      };
      bookings.push(booking);
      // saveOrUpdateBooking(booking);
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }
  return bookings;
}

function GroupScheduler({ match }) {
  const parsedGroupId = match?.params?.groupId;
  const { appUserId } = useContext(AuthenticationContext);
  const saveOrUpdateBooking = useSaveOrUpdateBooking();
  const { callAPI } = useAPI();
  const { data } = useUserGroupsByIdAndManager(
    parsedGroupId,
    appUserId,
    !!parsedGroupId,
  );
  const [isSaving, setIsSaving] = useState(false);
  const [schedulerPreview, setSchedulerPreview] = useState([]);
  const [scheduleFromDate, setScheduleFromDate] = useState(
    fromDateDefault.toISOString(),
  );
  // toDate is fromDate + 1 month
  const [scheduleToDate, setScheduleToDate] = useState(
    toDateDefault.toISOString(),
  );
  const { field } = data || {};

  const clubId = field?.split("/")[1];
  const fieldId = field?.split("/")[3];

  const {
    data: savedUserEvents,
    refreshData: refreshBookingData,
    isLoading,
  } = useBookingsByClubAndFields(
    clubId,
    fieldId,
    new Date(scheduleFromDate),
    new Date(scheduleToDate),
    !data,
  );

  const initState = () => {
    setScheduleFromDate(fromDateDefault.toISOString());
    setScheduleToDate(toDateDefault.toISOString());
    refreshBookingData();
    setSchedulerPreview([]);
  };

  useIonViewWillEnter(() => {
    initState();
  });

  useIonViewDidLeave(() => {
    initState();
  });

  const onPreviewClick = () => {
    setSchedulerPreview([]);
    refreshBookingData();
    const {
      participants,
      club,
      clubName,
      fieldName,
      field,
      fromTime,
      toTime,
      dayOfWeek,
      maxParticipants,
      tags,
      label,
      description,
    } = data;
    const scheduler = doPlanification(
      appUserId,
      new Date(scheduleFromDate),
      new Date(scheduleToDate),
      fromTime.seconds * 1000,
      toTime.seconds * 1000,
      Number(dayOfWeek),
      participants,
      club,
      field,
      parsedGroupId,
      maxParticipants,
      tags,
      label,
      description,
      clubName,
      fieldName,
    );

    // for each booking in scheduler, check if there is an overlap with savedUserEvents
    // if there is an overlap, add a overlapped property to the booking
    scheduler.forEach((booking) => {
      const overlaps = (savedUserEvents || []).filter((event) => {
        const from = new Date(event.from.seconds * 1000);
        const to = new Date(event.to.seconds * 1000);
        return (
          (booking.from >= from && booking.from < to) ||
          (booking.to > from && booking.to <= to) ||
          (booking.from <= from && booking.to >= to)
        );
      });
      const relatedBooking = overlaps.find(
        (overlap) =>
          overlap.from.seconds * 1000 === booking.from.getTime() &&
          overlap.to.seconds * 1000 === booking.to.getTime() &&
          overlap.relatedGroupId === booking.relatedGroupId,
      );
      if (overlaps.length > 0) {
        booking.overlapped = true;
        booking.overlaps = overlaps;
        booking.relatedBooking = relatedBooking;
        // if from and to are the same, it means that the booking is already present
        // in the database
      }
    });

    setSchedulerPreview(scheduler);
  };

  const onSaveClick = () => {
    const savePromises = [];
    setIsSaving(true);
    // remove overlapped and relatedBooking events from schedulerPreview
    const filteredSchedulerPreview = schedulerPreview.filter(
      (booking) => !booking.overlapped && !booking.relatedBooking,
    );

    debugger
    filteredSchedulerPreview.forEach((booking) => {
      savePromises.push(saveOrUpdateBooking(booking));
    });

    console.log("filteredSchedulerPreview", filteredSchedulerPreview);

    Promise.all(savePromises).then(async (results) => {
      debugger
      await callAPI({
        method: "post",
        url: "/subscriptions-logs",
        data: results,
      });
      // TODO: gestire abbonamento chiamando il servizio web
      setSchedulerPreview([]);
      refreshBookingData();
      setIsSaving(false);
    });
  };

  return (
    <IonPage>
      <IonContent>
        {(isLoading || isSaving) && <Spinner />}
        <IonTitle
          style={{
            color: "#062a40",
          }}
        >
          <h1>Pianificazione {data?.description}</h1>
        </IonTitle>
        <form>
          <IonList>
            <IonItem>
              <IonLabel>Data Inizio</IonLabel>
              <IonDatetimeButton datetime="schedulerFromDate"></IonDatetimeButton>
              <IonModal keepContentsMounted={true}>
                <IonDatetime
                  presentation="date"
                  id="schedulerFromDate"
                  value={scheduleFromDate}
                  onIonChange={(e) => {
                    setScheduleFromDate(e.detail.value);
                    setSchedulerPreview([]);
                  }}
                ></IonDatetime>
              </IonModal>
            </IonItem>
            <IonItem>
              <IonLabel>Data Fine</IonLabel>
              <IonDatetimeButton datetime="schedulerToDate"></IonDatetimeButton>
              <IonModal keepContentsMounted={true}>
                <IonDatetime
                  presentation="date"
                  id="schedulerToDate"
                  value={scheduleToDate}
                  onIonChange={(e) => {
                    setScheduleToDate(e.detail.value);
                    setSchedulerPreview([]);
                  }}
                ></IonDatetime>
              </IonModal>
            </IonItem>
          </IonList>
        </form>
        {/* <p>{JSON.stringify(schedulerPreview)}</p> */}
        <IonButton onClick={onPreviewClick}>Anteprima</IonButton>
        {schedulerPreview && (
          <div>
            {schedulerPreview
              // .sort((a, b) => a.from.seconds - b.from.seconds)
              .map((booking) => (
                <SchedulerCard
                  key={booking.id}
                  data={{
                    ...booking,
                    from: { seconds: booking.from.getTime() / 1000 },
                    to: { seconds: booking.to.getTime() / 1000 },
                  }}
                  imageUrl="smash.png"
                />
              ))}
          </div>
        )}
        <IonButton onClick={onSaveClick}>Salva</IonButton>
      </IonContent>
    </IonPage>
  );
}

GroupScheduler.propTypes = {};

export default GroupScheduler;

GroupScheduler.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      groupId: PropTypes.string,
    }),
  }),
};
