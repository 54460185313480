import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { IonRefresher, IonRefresherContent } from "@ionic/react";
import SubscriptionEditModal from "../../../../components/SubscriptionEditModal/SubscriptionEditModal";
import CardListHeader from "../../../../components/CardListHeader/CardListHeader";
import useGroupLogsByUserAndCoach from "../../../../hooks/groupLogs/useGroupLogsByUserAndCoach";
import LessonCard from "./LessonCard";
import useAPI from "../../../../hooks/basic/useAPI";

function LessonsContent({
  coachId,
  userId,
  showHeader = true,
  canAddSubscription = true,
  defaultFilterValue = "Non Pagati",
}) {
  const { callAPI } = useAPI();
  const { data, error, isLoading, refreshData } = useGroupLogsByUserAndCoach(
    coachId,
    userId,
    !coachId && !userId
  );

  console.log("data", data);
  const handleRefresh = (e) => {
    refreshData();
    e.detail.complete();
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [athletesDictionary, setAthletesDictionary] = useState({});
  const [selectedFilterValue, setSelectedFilterValue] =
    useState(defaultFilterValue);
  const [composedData, setComposedData] = useState([]);

  const filteredData = composedData?.filter((subscription) => {
    return true;
  });

  const loadData = async () => {
    const allAthletes = await callAPI({
      url: "/athletes-by-coach",
      params: {
        coachId,
      },
      preventCall: !coachId,
    });

    if (allAthletes) {
      const dictionary = allAthletes.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});
      setAthletesDictionary(dictionary);
      for (const subscription of data) {
        const athlete = dictionary[subscription.user_id];
        subscription.athlete = athlete;
      }
    }
    setComposedData(data);
  };

  useEffect(() => {
    if (!data) return;
    loadData();
  }, [data]);

  return (
    <div>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      {showHeader && (
        <CardListHeader
          filterOptions={{
            defaultSelectedOption: selectedFilterValue,
            options: ["Tutti", "Non Pagati"],
          }}
          canAddSubscription={canAddSubscription}
          onAddClick={() => {
            if (!coachId) return;
            setSelectedSubscription(null);
            setIsModalOpen(true);
          }}
          onFilterChange={(option) => {
            setSelectedFilterValue(option);
          }}
        />
      )}
      {filteredData &&
        filteredData.map((lessonGroup) => (
          <div key={lessonGroup.id}>
            <LessonCard key={lessonGroup.id} data={lessonGroup} />
          </div>
        ))}
      {isModalOpen && (
        <SubscriptionEditModal
          isOpen={isModalOpen}
          subscription={selectedSubscription}
          userId={userId}
          onDismiss={() => setIsModalOpen(false)}
          onConfirm={() => {
            setIsModalOpen(false);
            refreshData();
          }}
        />
      )}
    </div>
  );
}

LessonsContent.propTypes = {
  canAddSubscription: PropTypes.bool,
  coachId: PropTypes.string.isRequired,
  showHeader: PropTypes.bool,
  userId: PropTypes.string.isRequired,
};

export default LessonsContent;
