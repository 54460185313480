import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonIcon,
  IonImg,
  IonText,
} from "@ionic/react";
import { calendarOutline, cardOutline, peopleOutline, personOutline } from "ionicons/icons";

import "./styles/LessonCard.scss";
import { FormattedDate, FormattedTime } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import useLessonsByGroupLogId from "../../../../hooks/lessons/useLessonsByGroupLogId";

function isSubscriptionActive(start_date, end_date) {
  const now = new Date();
  return (
    now >= new Date(start_date.seconds * 1000) &&
    now <= new Date(end_date.seconds * 1000)
  );
}

function LessonCard({ data, imageUrl = "ticket.png", className }) {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const {
    data: lessons,
    error: lessonError,
    isLoading: isLoadingLessonData,
  } = useLessonsByGroupLogId(data.id, !isDetailsOpen);
  const {
    id,
    from_date,
    to_date,
    description,
    paid,
    payment_date,
    payment_channel,
    user_full_name,
    athlete,
  } = data;

  console.log("data", data);

  return (
    <IonCard
      key={id}
      style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
      className={
        className ? `LessonCard ${className}` : "LessonCard"
      }
    >
      <IonImg
        src={imageUrl}
        style={{
          width: "55px",
          paddingLeft: "1rem",
          boxSizing: "content-box",
        }}
      />
      <IonCardContent style={{ flex: 1, paddingLeft: "1rem" }}>
        <IonText>
          <h5>
            <IonIcon icon={calendarOutline}></IonIcon>
            <FormattedDate
              value={from_date.seconds * 1000}
              day="2-digit"
              month="2-digit"
              year="2-digit"
            />
            &nbsp;-&nbsp;
            <FormattedDate
              value={to_date.seconds * 1000}
              day="2-digit"
              month="2-digit"
              year="2-digit"
            />
          </h5>
        </IonText>
        <IonCardTitle>{description}</IonCardTitle>
        {athlete && (
          <IonText>
            <h5>
              <IonIcon icon={peopleOutline}></IonIcon>
              {`${athlete.fullName}`}
            </h5>
          </IonText>
        )}
        {data.lessons < data.minimum_lessons ? (
          <IonBadge color="danger">Lezioni da recuperare</IonBadge>
        ) : (
          ""
        )}
        {/* <IonText>
          <h5>
            <IonIcon icon={personOutline}></IonIcon>
            {user_full_name}
          </h5>
        </IonText> */}
        {/* <IonText>
          <h5>
            <IonIcon icon={cardOutline}></IonIcon>
            {paid ? (
              <span style={{ color: "green" }}>
                {payment_date ? (
                  <>
                    Pagato il&nbsp;
                    <FormattedDate
                      value={payment_date.seconds * 1000}
                      day="2-digit"
                      month="2-digit"
                      year="2-digit"
                    />
                  </>
                ) : null}
                {payment_channel && <>&nbsp;tramite {payment_channel}</>}
              </span>
            ) : (
              <span style={{ color: "red" }}>Non pagato</span>
            )}
          </h5>
        </IonText> */}
      </IonCardContent>
      <IonCardContent
        style={{
          flex: "0 1 auto",
          paddingLeft: "1rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <IonButton
          fill="clear"
          onClick={(e) => {
            e.stopPropagation();
            setIsDetailsOpen(!isDetailsOpen);
          }}
        >
          <FontAwesomeIcon
            icon={faChevronUp}
            style={{
              fontSize: "1.5em",
            }}
          />
        </IonButton>
      </IonCardContent>
      {isDetailsOpen && (
        <IonCardContent
          style={{
            flexBasis: "100%",
          }}
        >
          <h2>Diario lezioni</h2>
          <div key={data.id}>
            <h6>
              {data.description}
              &nbsp;-&nbsp;(
              <FormattedDate
                value={data.from_date.seconds * 1000}
                day="2-digit"
                month="2-digit"
                year="2-digit"
              />
              &nbsp;-&nbsp;
              <FormattedDate
                value={data.to_date.seconds * 1000}
                day="2-digit"
                month="2-digit"
                year="2-digit"
              />
              )
            </h6>
            <h6>
              Lezioni svolte {data.lessons || "-"} su{" "}
              {data.minimum_lessons} pianificate
            </h6>
            <ul>
              {(lessons || []).map((lesson) => (
                <li key={lesson.id}>
                  <FormattedDate
                    value={lesson.to_date.seconds * 1000}
                    day="2-digit"
                    month="2-digit"
                    year="2-digit"
                    weekday="long"
                  />
                  &nbsp;-&nbsp;
                  <FormattedTime
                    value={lesson.from_date.seconds * 1000}
                    hour="2-digit"
                    minute="2-digit"
                  />
                </li>
              ))}
            </ul>
          </div>
        </IonCardContent>
      )}
    </IonCard>
  );
}

LessonCard.propTypes = {
  href: PropTypes.string,
  imageUrl: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string,
    start_date: PropTypes.shape({ seconds: PropTypes.number }),
    end_date: PropTypes.shape({ seconds: PropTypes.number }),
    participants: PropTypes.array,
    fieldName: PropTypes.string,
    clubName: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    paid: PropTypes.bool,
    payment_date: PropTypes.shape({ seconds: PropTypes.number }),
    payment_channel: PropTypes.string,
  }),
};

export default LessonCard;
