import React, { useContext, useEffect, useState } from "react";
import {
  IonAlert,
  IonButton,
  IonContent,
  IonFooter,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  useIonToast,
  useIonViewWillEnter,
  useIonViewDidLeave,
} from "@ionic/react";

import DaySelector from "../../components/DaySelector/DaySelector";
import useClubsByCoach from "../../hooks/useClubsByCoach";
import { AuthenticationContext } from "../../providers/AuthenticationProvider/AuthenticationProvider";
import useFieldsByClub from "../../hooks/useFieldsByClub";
import useBookingsByClubAndFields from "../../hooks/bookings/useBookingsByClubAndFields";
import TimeSelector from "../../components/DaySelector/TimeSelector";
import PeriodLengthSelector from "../../components/DaySelector/PeriodLengthSelector";
import useSaveOrUpdateBooking from "../../hooks/bookings/useSaveOrUpdateBooking";
import { getBookablePeriods, getBookedPeriods } from "../../utils/timeSchedule";
import { useHistory } from "react-router";
import ClubSelector from "../../components/DaySelector/ClubSelector";
import FieldSelector from "../../components/DaySelector/FieldSelector";
import AthletesModal from "../../components/AthletesModal/AthletesModal";
import useAthletesByCoach from "../../hooks/useAthletesByCoach";
import useAPI from "../../hooks/basic/useAPI";

const maxBookableFutureDays = 7;
const bookingTimeSlotSizeInMinutes = 30;
const minimumBookingDurationInMinutes = 60;
// the user can book only for the next 3 hours
const maximumBookableHours = 3;
const maxBookableSlots =
  maximumBookableHours *
  (minimumBookingDurationInMinutes / bookingTimeSlotSizeInMinutes);

function Add() {
  const history = useHistory();
  const [present] = useIonToast();
  const { appUserId } = useContext(AuthenticationContext);
  const [clubId, setClubId] = useState(null);
  const [fieldId, setFieldId] = useState(null);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [bookablePeriods, setBookablePeriods] = useState([]);
  const [bookedPeriods, setBookedPeriods] = useState({});
  const [selectedHour, setSelectedHour] = useState(null);
  const [bookableSlots, setBookableSlots] = useState(0);
  const [bookingDurationInMinutes, setBookingDurationInMinutes] = useState(0);
  const [saveButtonEnabled, setSaveButtonEnabled] = useState(false);
  const [enableConfirmationAlert, setEnableConfirmationAlert] = useState(false);
  const [openAthletesModal, setOpenAthletesModal] = useState(false);

  const [selectedAthletes, setSelectedAthletes] = useState([]);
  const { callAPI } = useAPI();
  const { data } = useClubsByCoach(appUserId);
  const { data: fields } = useFieldsByClub(clubId);

  const resetState = () => {
    setClubId(null);
    setFieldId(null);
    setSelectedDay(new Date());
    setSelectedHour(null);
    setBookableSlots(0);
    setBookingDurationInMinutes(0);
    setSaveButtonEnabled(false);
  };

  useIonViewWillEnter(() => {
    resetState();
  });

  useIonViewDidLeave(() => {
    resetState();
  });

  // Check if i can enable the save button
  useEffect(() => {
    if (
      clubId &&
      fieldId &&
      selectedDay &&
      selectedHour &&
      bookingDurationInMinutes
    ) {
      setSaveButtonEnabled(true);
    } else {
      setSaveButtonEnabled(false);
    }
  }, [clubId, fieldId, selectedDay, selectedHour, bookingDurationInMinutes]);

  const onClubsChange = (clubId) => {
    setClubId(clubId);
    setFieldId(null);
    setSelectedDay(new Date());
    setSelectedHour(null);
    setBookableSlots(0);
    setBookingDurationInMinutes(0);
  };

  const saveOrUpdateBooking = useSaveOrUpdateBooking();

  const fromDateMidnight = new Date(selectedDay);
  fromDateMidnight.setHours(0, 0, 0, 0);
  const toDateMidnight = new Date(selectedDay);
  toDateMidnight.setHours(23, 59, 59, 999);

  const { data: bookings = [] } = useBookingsByClubAndFields(
    clubId,
    fieldId,
    fromDateMidnight,
    toDateMidnight,
    !clubId || !fieldId,
  );
  const { data: athletes } = useAthletesByCoach(appUserId);

  useEffect(() => {
    if (fields && fields.length > 0) {
      setFieldId(fields[0].id);
    }
  }, [fields]);

  useEffect(() => {
    // get valid booking periods for the selected field
    if (clubId && fieldId) {
      const field = fields.find((f) => f.id === fieldId);
      if (field) {
        const bookingPeriods = field.prenotabile;
        const { bookedTimeSummary, bookablePeriods } = getBookablePeriods(
          bookingPeriods,
          selectedDay,
          bookingTimeSlotSizeInMinutes,
        );

        const bookedPeriods = getBookedPeriods(
          bookings,
          bookedTimeSummary,
          bookingTimeSlotSizeInMinutes,
        );

        setBookablePeriods(bookablePeriods);
        setBookedPeriods(bookedPeriods);
      }
    }
  }, [clubId, fieldId, selectedDay, bookings]);

  const bookField = async () => {
    const fromDate = new Date(selectedDay);

    // add the selected hour to fromDate
    const selectedHourSplitted = selectedHour.split(":");
    fromDate.setHours(selectedHourSplitted[0], selectedHourSplitted[1], 0, 0);
    const toDate = new Date(selectedDay);
    toDate.setHours(
      fromDate.getHours(),
      fromDate.getMinutes() + bookingDurationInMinutes,
      0,
      0,
    );

    const booking = {
      club: `/societa/${clubId}`,
      field: `societa/${clubId}/campo/${fieldId}`,
      participantUserIds: selectedAthletes
        .filter((a) => a.userId)
        .map((a) => a.userId),
      participants: selectedAthletes,
      from: fromDate,
      to: toDate,
      relatedGroupId: null,
      relatedSupervisorId: appUserId,
      clubName: data.find((c) => c.id === clubId).nome,
      fieldName: fields.find((f) => f.id === fieldId).nome,
      description: "Lezione",
    };

    // await saveOrUpdateBooking(booking);
    await callAPI({
      method: "post",
      url: "/create-single-lesson",
      data: booking,
    });
  };

  return (
    <IonPage className="AddBooking">
      <IonContent>
        <IonTitle
          style={{
            color: "#062a40",
          }}
        >
          <h1>Nuova lezione</h1>
        </IonTitle>
        <IonList>
          <IonItem>
            <IonLabel className="custom-label">Club</IonLabel>
            <ClubSelector
              data={data}
              selectedClubId={clubId}
              onChangeClub={onClubsChange}
            />
          </IonItem>
          <IonItem>
            <IonLabel className="custom-label">Campo</IonLabel>
            <FieldSelector
              data={fields}
              selectedFieldId={fieldId}
              onChangeField={setFieldId}
            />
          </IonItem>
          {clubId && (
            <IonItem>
              <IonLabel className="custom-label">Giorno</IonLabel>
              <DaySelector
                daysLength={maxBookableFutureDays}
                onChangeDay={(day) => setSelectedDay(day)}
                selectedDay={selectedDay}
              />
            </IonItem>
          )}
          {fieldId && (
            <IonItem>
              <IonLabel className="custom-label">Orario</IonLabel>
              <TimeSelector
                bookablePeriods={bookablePeriods}
                bookedSummary={bookedPeriods}
                onChangeTime={(hour, bookableSlots) => {
                  setSelectedHour(hour);
                  setBookableSlots(bookableSlots);
                }}
                minBookableSlots={
                  minimumBookingDurationInMinutes / bookingTimeSlotSizeInMinutes
                }
              />
            </IonItem>
          )}
          {selectedHour && (
            <IonItem>
              <IonLabel className="custom-label">Durata</IonLabel>
              <PeriodLengthSelector
                minPeriondInMinutes={minimumBookingDurationInMinutes}
                singleSlotInMinutes={bookingTimeSlotSizeInMinutes}
                totalSlots={Math.min(maxBookableSlots, bookableSlots)}
                onChangePeriodLength={(minutes) => {
                  setBookingDurationInMinutes(minutes);
                }}
              />
            </IonItem>
          )}
        </IonList>
        <IonItem onClick={() => setOpenAthletesModal(true)}>
          <IonButton>Open</IonButton>
          {selectedAthletes.map((athlete) => athlete.fullName).join(", ")}
        </IonItem>
      </IonContent>
      <IonAlert
        isOpen={enableConfirmationAlert}
        header="Confermi la prenotazione?"
        buttons={[
          {
            text: "Annulla",
            role: "cancel",
            handler: async () => {
              setEnableConfirmationAlert(false);
            },
          },
          {
            text: "Conferma",
            role: "confirm",
            handler: async () => {
              await bookField();
              setEnableConfirmationAlert(false);
              present({
                message: "Prenotazione effettuata con successo!",
                duration: 3000,
                color: "success",
              });
              history.push("/");
            },
          },
        ]}
        onDidDismiss={() => setEnableConfirmationAlert(false)}
      ></IonAlert>
      <IonFooter className="ion-no-border ion-padding">
        <IonButton
          color="success"
          expand="block"
          size="large"
          disabled={!saveButtonEnabled}
          onClick={() => setEnableConfirmationAlert(true)}
        >
          Crea
        </IonButton>
      </IonFooter>
      <AthletesModal
        isOpen={openAthletesModal}
        athletes={athletes}
        selectedAthletes={selectedAthletes}
        onConfirm={(athletes) => {
          setOpenAthletesModal(false);
          setSelectedAthletes(athletes);
        }}
        onDismiss={() => setOpenAthletesModal(false)}
      />
    </IonPage>
  );
}

Add.propTypes = {};

export default Add;
