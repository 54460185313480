import { useContext, useEffect } from "react";
import useFirestore from "../basic/useFirestore";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";

function useLessonsByGroupLogId(groupLogId, preventCall) {
  const { firestoreDb } = useContext(FirebaseContext);
  const firestoreQueryMethod = async () =>
    getDocs(
      query(
        collection(firestoreDb, "lesson-logs"),
        where("group_log_id", "==", groupLogId),
        orderBy("from_date", "asc"),
      ),
    );

  const parsingFuntion = (data) => {
    const parsedData = data.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
    return parsedData;
  };

  const { data, error, isLoading, refreshData } = useFirestore(
    firestoreQueryMethod,
    parsingFuntion,
    preventCall,
  );

  useEffect(() => {
    if (preventCall) return;
    refreshData();
  }, [groupLogId, preventCall]);

  return {
    data,
    error,
    isLoading,
    refreshData,
  };
}

export default useLessonsByGroupLogId;
