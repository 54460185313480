import { IonContent, IonLabel, IonPage, IonSegment, IonSegmentButton } from "@ionic/react";
import React, { useContext, useState } from "react";
import { AuthenticationContext } from "../../../providers/AuthenticationProvider/AuthenticationProvider";
import SubscriptionsContent from "../UserManagement/sub/SubscriptionsContent";
import GroupsContent from "../UserManagement/sub/GroupsContent";

const SubscriptionLogs = () => {
  const { appUserId, isCoach } = useContext(AuthenticationContext);
  const [currentTab, setCurrentTab] = useState("pacchetti");
  const isPacchetti = currentTab === "pacchetti";
  const isAltro = currentTab === "altro";
  return (
    <IonPage>
      <IonContent>
        <IonSegment
          value={currentTab}
          scrollable
          onIonChange={(evt) => setCurrentTab(evt.detail.value)}
        >
          <IonSegmentButton value="pacchetti">
            <IonLabel>Pacchetti</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="altro">
            <IonLabel>Altro</IonLabel>
          </IonSegmentButton>
        </IonSegment>
        {isPacchetti && (
          <SubscriptionsContent
            coachId={isCoach && appUserId}
            userId={!isCoach && appUserId}
            canAddSubscription={false}
          />
        )}
        {isAltro && (
          <GroupsContent
            coachId={isCoach && appUserId}
            userId={!isCoach && appUserId}
            canAddSubscription={false}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default SubscriptionLogs;
