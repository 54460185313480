import React from "react";
import PropTypes from "prop-types";
import useDataProvider from "./useDataProvider";

/**
 * Hook, used for receiving actual data for passed params.
 */
function useCommonDataProvider({
  url,
  headers,
  requestType,
  params,
  // To prevent call and maintain same data
  preventCall,
  dataModifierFn,
}) {
  const defaultDataModifierFn = React.useCallback(
    (response) => response && response.data,
    [],
  );
  return useDataProvider({
    url,
    headers,
    requestType,
    params,
    preventCall,
    dataModifierFn: dataModifierFn || defaultDataModifierFn,
  });
}

useCommonDataProvider.propTypes = {
  /** Endpoint to call to */
  url: PropTypes.string.isRequired,
  /** Type of the request */
  requestType: PropTypes.oneOf(["get", "post", "put"]),
  /** Params to pass into request */
  params: PropTypes.shape({}),
  /** Headers object to pass into request */
  headers: PropTypes.shape({}),
  /**
   * Flag, while truthy - request will not be done,
   * to prevent call and provide same, old data */
  preventCall: PropTypes.bool,
  /**
   * Function to extract chunk of data from RestAPI response. Remember to wrap in useCallback
   * Example:
   *    React.useCallback((response) => response && response.data && response.data.content, []);
   */
  dataModifierFn: PropTypes.func,
};

useCommonDataProvider.defaultProps = {
  requestType: "get",
  headers: null,
};

export default useCommonDataProvider;
