import { useContext, useState } from "react";
import axios from "axios";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";
import { AuthenticationContext } from "../../providers/AuthenticationProvider/AuthenticationProvider";

const useAPI = () => {
  const { apiBaseUrl } = useContext(FirebaseContext);
  const { user } = useContext(AuthenticationContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const callAPI = async ({
    method = "get",
    url,
    data = null,
    params = null,
    headers = {},
  }) => {
    const token = await user.getIdToken(); //
    try {
      setLoading(true);
      setError(null);

      let managedUrl = url;
      if (url && url[0] === "/") {
        managedUrl = `${apiBaseUrl}${url}`;
      }

      const config = {
        method,
        url: managedUrl,
        headers: {
          Authorization: `Bearer ${token}`,
          ...headers,
        },
        ...(data && { data }),
        ...(params && { params }),
      };

      const response = await axios(config);
      return response.data;
    } catch (err) {
      setError(err.response?.data || err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    callAPI,
  };
};

export default useAPI;
