import React from "react";
import PropTypes from "prop-types";
import { IonContent, IonPage, IonTitle } from "@ionic/react";

function Infos() {
  return (
    <IonPage>
      <IonContent>
        <div>
          <h1
            style={{
              display: "flex",
              alignItems: "center",
              padding: "0 20px",
            }}
          >
            Informazioni utili
          </h1>
          <div className="ion-text-center">
            <img src="logo.png" alt="logo" style={{ maxWidth: "150px" }} />
          </div>
          <div
            style={{
              margin: "0 20px",
            }}
          >
            <div>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/tweener-bb9d7.appspot.com/o/public%2Fregolamento-scuola-tennis-janus-tennis-academy.pdf?alt=media&token=61bf3515-e71e-4f86-950a-fb0007f72d12"
                target="_blank"
                rel="noreferrer"
              >
                Regolamento scuola tennis
              </a>
            </div>
            <div>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/tweener-bb9d7.appspot.com/o/public%2Fscheda-ammissione-socio-2025.pdf?alt=media&token=1fa18dc9-4e65-486d-bf5a-42c9deff789e"
                target="_blank"
                rel="noreferrer"
              >
                Scheda ammissione socio
              </a>
            </div>
            <div>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/tweener-bb9d7.appspot.com/o/public%2Fquote-corsi-janus-tennis-academy.pdf?alt=media&token=f418b165-8fff-4040-a429-d16f18312c0f"
                target="_blank"
                rel="noreferrer"
              >
                Quote corsi
              </a>
            </div>
            <div>
              <a
                href="https://firebasestorage.googleapis.com/v0/b/tweener-bb9d7.appspot.com/o/public%2Fstatuto-jta.pdf?alt=media&token=dd435e40-2798-4a56-8fc8-6a42dbe4b579"
                target="_blank"
                rel="noreferrer"
              >
                Statuto
              </a>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

Infos.propTypes = {};

export default Infos;
