import React, { useContext } from "react";
import { AuthenticationContext } from "../../../providers/AuthenticationProvider/AuthenticationProvider";
import {
  IonAlert,
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRouterLink,
  IonTitle,
  useIonViewWillEnter,
} from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import Spinner from "../../../components/Spinner/Spinner";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import useAthletesByCoach from "../../../hooks/useAthletesByCoach";
import useUsers from "../../../hooks/users/useUsers";
import AthletesModal from "../../../components/AthletesModal/AthletesModal";
import useAddCoachAthletes from "../../../hooks/coach/useAddCoachAthletes";
import useRemoveCoachAthletes from "../../../hooks/coach/useRemoveCoachAthletes";
import NewAthleteModal from "../../../components/NewAthleteModal/NewAthleteModal";
import useUserFakeCreation from "../../../hooks/bookings/useUserFakeCreation";
import { addCircleOutline, personAddOutline } from "ionicons/icons";
import CertificateExpirationIcon from "./sub/CertificateExpirationIcon";
import EditCoachUserModal from "./sub/EditCoachUserModal";
import useUpdateCoachAthlete from "../../../hooks/coach/useUpdateCoachAthlete";
import useUpdateUserData from "../../../hooks/users/useUpdateUserData";

const CoachUsers = () => {
  const { appUserId } = useContext(AuthenticationContext);
  const addAthletes = useAddCoachAthletes();
  const removeAthlete = useRemoveCoachAthletes();
  const createFakeUser = useUserFakeCreation();
  const updateCoachAthlete = useUpdateCoachAthlete();
  const updateUserData = useUpdateUserData();
  const [openAthletesModal, setOpenAthletesModal] = React.useState(false);
  const [selectedAthleteId, setSelectedAthleteId] = React.useState(null);
  const [editUser, setEditUser] = React.useState(null);
  const {
    data,
    error,
    isLoading: isUserCoachDataLoading,
    refreshData,
  } = useAthletesByCoach(appUserId);

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showNewAthleteModal, setShowNewAthleteModal] = React.useState(false);

  const {
    data: allUsersData,
    error: allUsersError,
    isLoading: allUsersIsLoading,
    refreshData: refreshAllUsersData,
  } = useUsers();

  const isLoading = isUserCoachDataLoading;

  useIonViewWillEnter(() => {
    refreshData();
    refreshAllUsersData();
  });

  return (
    <IonPage>
      <IonContent id="main-content">
        {(isLoading || allUsersIsLoading) && <Spinner />}
        {(error || allUsersError) && (
          <p>Error: {error.message || allUsersError.message}</p>
        )}
        {/* {data && data.length > 0 && ( */}
        <div>
          <IonTitle
            style={{
              color: "#062a40",
            }}
          >
            <h1
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              Utenti
              <IonButton
                style={{
                  marginLeft: "auto",
                  textTransform: "uppercase",
                  marginRight: "1rem",
                }}
                onClick={() => setShowNewAthleteModal(true)}
              >
                Crea
                <IonIcon slot="end" icon={addCircleOutline} />
              </IonButton>
              <IonButton
                style={{
                  textTransform: "uppercase",
                }}
                onClick={() => setOpenAthletesModal(true)}
              >
                Aggiungi
                <IonIcon slot="end" icon={personAddOutline} />
              </IonButton>
            </h1>
          </IonTitle>
        </div>
        {/* )} */}
        <IonList>
          {data &&
            data
              .sort((a, b) =>
                `${a.cognome} ${a.nome}`.localeCompare(
                  `${b.cognome} ${b.nome}`,
                ),
              )
              .map((user) => (
                <IonRouterLink
                  key={user.id}
                  routerLink={`/user-management/${user.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <IonItem>
                    <IonLabel>{`${user.cognome} ${user.nome}`}</IonLabel>
                    <CertificateExpirationIcon
                      expireDate={user.certificate_expire}
                    // onIconClick={() => setEditUser(user)}
                    />
                    <CertificateExpirationIcon
                      expireDate={user.subscription_expire}
                      customIcon={faCheckCircle}
                    // onIconClick={() => setEditUser(user)}
                    />
                    <IonIcon
                      icon={trashOutline}
                      slot="end"
                      onClick={() => {
                        setSelectedAthleteId(user.id);
                        setShowDeleteModal(true);
                      }}
                    ></IonIcon>
                  </IonItem>
                </IonRouterLink>
              ))}
        </IonList>
        <EditCoachUserModal
          user={editUser}
          isOpen={!!editUser}
          onWillDismiss={() => setEditUser(null)}
          onConfirmModal={async (updatedUser) => {
            await updateCoachAthlete(appUserId, updatedUser);
            // update user fields
            await updateUserData(updatedUser.id, {
              certificate_expire: updatedUser.certificate_expire,
            });
            setEditUser(null);
            refreshData();
            refreshAllUsersData();
          }}
        />
        <NewAthleteModal
          isOpen={showNewAthleteModal}
          onDismiss={() => setShowNewAthleteModal(false)}
          onConfirm={async (newAthlete) => {
            const fakeUser = await createFakeUser(
              newAthlete.firstName,
              newAthlete.lastName,
            );
            await addAthletes(appUserId, [fakeUser]);
            setShowNewAthleteModal(false);
            refreshData();
            refreshAllUsersData();
          }}
        />
        {allUsersData && data && (
          <AthletesModal
            hideSelectedAthletes
            athletes={allUsersData}
            selectedAthletes={data}
            isOpen={openAthletesModal}
            onDismiss={() => setOpenAthletesModal(false)}
            onConfirm={async (selectedAthletes) => {
              await addAthletes(appUserId, selectedAthletes);
              setOpenAthletesModal(false);
              refreshData();
              refreshAllUsersData();
            }}
          />
        )}
        <IonAlert
          isOpen={showDeleteModal}
          header="Vuoi rimuoverlo dagli atleti?"
          trigger="update-alert"
          buttons={[
            {
              text: "Annulla",
              role: "cancel",
              handler: async () => {
                setShowDeleteModal(false);
                setSelectedAthleteId(null);
                refreshData();
                refreshAllUsersData();
              },
            },
            {
              text: "Conferma",
              role: "confirm",
              handler: async () => {
                setShowDeleteModal(false);
                await removeAthlete(appUserId, selectedAthleteId);
                setSelectedAthleteId(null);
                refreshData();
                refreshAllUsersData();
              },
            },
          ]}
        ></IonAlert>
      </IonContent>
    </IonPage>
  );
};

export default CoachUsers;
