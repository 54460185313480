import React from 'react'
import PropTypes from 'prop-types'
import { IonImg } from "@ionic/react"

import "../styles/SubscriptionTypeSlide.scss"

function SubscriptionTypeSlide({
  data
}) {
  return (
    <div className="SubscriptionTypeSlide">
      <img src="ticket.png" />
      <h4 className="SubscriptionTypeSlide_title">{data.name}</h4>
    </div>
  )
}

SubscriptionTypeSlide.propTypes = {}

export default SubscriptionTypeSlide
