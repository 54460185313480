import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import useSubscriptionsByUserAndCoach from "../../../../hooks/subscriptions/useSubscriptionsByUserAndCoach";
import SubscriptionCard from "./SubscriptionCard";
import {
  IonActionSheet,
  IonButton,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import SubscriptionEditModal from "../../../../components/SubscriptionEditModal/SubscriptionEditModal";
import CardListHeader from "../../../../components/CardListHeader/CardListHeader";
import useNotPaidGroupLogsByUserAndCoach from "../../../../hooks/groupLogs/useNotPaidGroupLogsByUserAndCoach";
import GroupLogCard from "./GroupLogCard";
import BuySubscriptionModal from "../../../../components/BuySubscriptionModal/BuySubscriptionModal";

function GroupsContent({
  coachId,
  userId,
  showHeader = true,
  canAddSubscription = true,
  defaultFilterValue = "Non Pagati",
}) {
  const { data, error, isLoading, refreshData } =
    useNotPaidGroupLogsByUserAndCoach(coachId, userId);

  const handleRefresh = (e) => {
    refreshData();
    e.detail.complete();
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  return (
    <div>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      {data &&
        data.map((subscription) => (
          <div
            key={subscription.id}
            onClick={() => {
              // setSelectedSubscription(subscription);
              // setIsModalOpen(true);
            }}
          >
            <GroupLogCard key={subscription.id} data={subscription} />
          </div>
        ))}
      {isModalOpen && coachId && (
        <BuySubscriptionModal
          type="week"
          supervisorId={coachId}
          onDismiss={() => setIsModalOpen(false)}
          onConfirm={() => {
            setIsModalOpen(false);
            refreshData();
          }}
        />
      )}
    </div>
  );
}

GroupsContent.propTypes = {
  canAddSubscription: PropTypes.bool,
  coachId: PropTypes.string.isRequired,
  showHeader: PropTypes.bool,
  userId: PropTypes.string.isRequired,
};

export default GroupsContent;
