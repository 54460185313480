import React, { useState } from "react";
import PropTypes from "prop-types";
import { IonActionSheet, IonButton } from "@ionic/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquarePlus,
  faFilter,
  faArrowDownShortWide,
} from "@fortawesome/free-solid-svg-icons";

import "./styles/CardListHeader.scss";

function CardListHeader({
  canAddSubscription = true,
  onAddClick = () => {
    console.log("Add button clicked");
  },
  onFilterChange = () => {
    console.log("Filter changed");
  },
  filterOptions = { defaultSelectedOption: "Tutti", options: ["Tutti"] },
}) {
  const { defaultSelectedOption, options } = filterOptions;
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [selectedFilterValue, setSelectedFilterValue] = useState(
    defaultSelectedOption,
  );

  const handleSelect = (option) => {
    setShowActionSheet(false);
    setSelectedFilterValue(option);
    onFilterChange(option);
  };

  return (
    <div className="CardListHeader">
      <IonButton
        className="CardListHeader--filter-button"
        fill="clear"
        onClick={() => setShowActionSheet(true)} // Apre manualmente la select
      >
        <FontAwesomeIcon
          icon={faFilter}
          style={{
            fontSize: "1.5em",
          }}
        />
      </IonButton>
      {canAddSubscription && (
        <IonButton
          className="CardListHeader--create-button"
          fill="clear"
          onClick={() => onAddClick()}
        >
          <FontAwesomeIcon
            icon={faSquarePlus}
            style={{
              fontSize: "1.5em",
            }}
          />
        </IonButton>
      )}
      <IonActionSheet
        className="CardListHeader--action-sheet"
        isOpen={showActionSheet} // Mostra l'ActionSheet
        onDidDismiss={() => setShowActionSheet(false)} // Chiude l'ActionSheet
        buttons={[
          ...options.map((option) => ({
            text: option,
            handler: () => {
              setSelectedFilterValue(option);
              handleSelect(option);
            },
            cssClass: option === selectedFilterValue ? "selected" : "",
          })),
          {
            text: "Annulla",
            role: "cancel", // Permette di annullare senza selezionare
          },
        ]}
      />
    </div>
  );
}

CardListHeader.propTypes = {
  onAddClick: PropTypes.func,
  onFilterChange: PropTypes.func,
  filterOptions: PropTypes.shape({
    defaultSelectedOption: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default CardListHeader;
