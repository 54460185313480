import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import useAthleteByCoach from "../../../../hooks/useAthleteByCoach";
import { AuthenticationContext } from "../../../../providers/AuthenticationProvider/AuthenticationProvider";
import { FormattedDate } from "react-intl";
import EditCoachUserModal from "../../CoachUsers/sub/EditCoachUserModal";
import useUpdateCoachAthlete from "../../../../hooks/coach/useUpdateCoachAthlete";
import useUpdateUserData from "../../../../hooks/users/useUpdateUserData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";

function AnagraphicContent({ userId }) {
  const { appUserId } = useContext(AuthenticationContext);
  const { data, refreshData } = useAthleteByCoach(
    appUserId,
    userId,
    !userId && !appUserId,
  );
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const updateCoachAthlete = useUpdateCoachAthlete();
  const updateUserData = useUpdateUserData();

  console.log("aaaa", data);
  return (
    <div className="ion-text-center ion-padding">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "50px",
        }}
      >
        <img src="/tennis-player_3295395.png" alt="Avatar" width="150" />
        <h3
          style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}
        >
          {data?.fullName}
          &nbsp;
          <FontAwesomeIcon
            style={{ cursor: "pointer", fontSize: ".9rem" }}
            icon={faPen}
            onClick={() => setShowEditUserModal(true)}
          />
        </h3>
        <h5>{data?.email}</h5>
      </div>
      {
        // if user has expired certificate, show a notification
        data?.certificate_expire && (
          <div>
            <h4 style={{ fontWeight: "700" }}>Scadenza certificato medico</h4>
            <FormattedDate
              value={data.certificate_expire.seconds * 1000}
              day="2-digit"
              month="long"
              year="numeric"
            />
          </div>
        )
      }
      {
        // if user has expired certificate, show a notification
        data?.subscription_expire && (
          <div>
            <h4 style={{ fontWeight: "700" }}>Scadenza iscrizione</h4>
            <FormattedDate
              value={data.subscription_expire.seconds * 1000}
              day="2-digit"
              month="long"
              year="numeric"
            />
          </div>
        )
      }
      {showEditUserModal && (
        <EditCoachUserModal
          user={data}
          isOpen={showEditUserModal}
          onWillDismiss={() => setShowEditUserModal(null)}
          onConfirmModal={async (updatedUser) => {
            console.log("updatedUser", updatedUser);
            await updateCoachAthlete(appUserId, updatedUser);
            // update user fields
            await updateUserData(updatedUser.id, {
              certificate_expire: updatedUser.certificate_expire,
              subscription_expire: updatedUser.subscription_expire,
            });
            setShowEditUserModal(null);
            refreshData();
          }}
        />
      )}
    </div>
  );
}

AnagraphicContent.propTypes = {
  userId: PropTypes.string,
};

export default AnagraphicContent;
