import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonDatetime,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonToolbar,
} from "@ionic/react";
import useSaveOrUpdatePayment from "../../hooks/subscriptions/useSaveOrUpdatePayment";
import useSubscriptions from "../../hooks/subscriptions/useSubscriptions";
import { AuthenticationContext } from "../../providers/AuthenticationProvider/AuthenticationProvider";
import useUserAppData from "../../hooks/users/useUserAppData";
import useAthletesByCoach from "../../hooks/useAthletesByCoach";

function SubscriptionEditModal({
  subscription,
  isOpen,
  onDismiss,
  onConfirm,
  userId,
}) {
  const [temporarySubscriptions, setTemporarySubscriptions] = useState(null);
  const [showFromCalendarModal, setShowFromCalendarModal] = useState(false);
  const [showToCalendarModal, setShowToCalendarModal] = useState(false);
  const [showPaymentDateModal, setShowPaymentDateModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(userId);
  const { appUserId } = useContext(AuthenticationContext);

  const saveOrUpdatePayment = useSaveOrUpdatePayment();
  const { data: subscriptions } = useSubscriptions(appUserId);
  const { data: athletes } = useAthletesByCoach(appUserId);

  useEffect(() => {
    if (isOpen && !subscription) {
      setTemporarySubscriptions({
        id: null,
        start_date: new Date(),
        end_date: new Date(),
        paid: false,
        payment_date: null,
        payment_channel: "",
        payment_note: "",
        subscription_id: null,
      });
    }
  }, [isOpen, subscription]);

  useEffect(() => {
    if (isOpen && subscription) {
      setTemporarySubscriptions({
        ...subscription,
        start_date: new Date(subscription.start_date.seconds * 1000),
        end_date: new Date(subscription.end_date.seconds * 1000),
        payment_date: subscription.payment_date
          ? new Date(subscription.payment_date.seconds * 1000)
          : null,
      });
    }
  }, [isOpen, subscription]);

  const onDismissModal = () => {
    onDismiss();
  };

  const onSave = async () => {
    if (subscription) {
      await saveOrUpdatePayment({
        id: temporarySubscriptions.id,
        start_date: temporarySubscriptions.start_date,
        end_date: temporarySubscriptions.end_date,
        paid: temporarySubscriptions.paid,
        payment_date: temporarySubscriptions.payment_date,
        payment_channel: temporarySubscriptions.payment_channel,
        payment_note: temporarySubscriptions.payment_note,
        user_id: selectedUserId,
      });
    } else {
      const selectedSubscription = subscriptions.find(
        (s) => s.id === temporarySubscriptions.subscription_id,
      );
      const userData = athletes.find((a) => a.id === selectedUserId);
      await saveOrUpdatePayment({
        description: selectedSubscription.name,
        end_date: temporarySubscriptions.end_date,
        groups: selectedSubscription.groups || null,
        start_date: temporarySubscriptions.start_date,
        paid: temporarySubscriptions.paid,
        payment_channel: temporarySubscriptions.payment_channel,
        payment_date: temporarySubscriptions.payment_date,
        payment_note: temporarySubscriptions.payment_note,
        periodicity: selectedSubscription.periodicity || null,
        subscription_id: temporarySubscriptions.subscription_id,
        supervisor_id: appUserId,
        type: selectedSubscription.type || null,
        user_id: selectedUserId,
        user_full_name: userData.fullName,
      });
    }
    onConfirm();
  };

  if (!temporarySubscriptions) {
    return null;
  }

  const {
    id,
    description,
    paid,
    payment_channel,
    payment_note,
    subscription_id,
  } = temporarySubscriptions;
  const fromDate = temporarySubscriptions.start_date;
  const toDate = temporarySubscriptions.end_date;
  const paymentDate = temporarySubscriptions.payment_date;

  return (
    <IonModal isOpen={isOpen} onDidDismiss={() => onDismissModal()}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={onDismissModal}>Annulla</IonButton>
          </IonButtons>
          <IonButtons slot="end">
            <IonButton strong={true} onClick={onSave}>
              Conferma
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <IonList>
          {id && (
            <IonItem>
              <IonInput
                className="ion-text-right"
                label="Tipologia"
                name="description"
                value={description}
                placeholder="Tipologia"
                disabled
              ></IonInput>
            </IonItem>
          )}
          {!id && (
            <IonItem>
              <IonLabel className="custom-label">
                Seleziona un pacchetto
              </IonLabel>
              <IonSelect
                aria-label="Seleziona un pacchetto"
                label=""
                interface="action-sheet"
                placeholder="Seleziona un pacchetto"
                value={subscription_id}
                onIonChange={(e) => {
                  setTemporarySubscriptions({
                    ...temporarySubscriptions,
                    subscription_id: e.detail.value,
                  });
                }}
              >
                {subscriptions &&
                  subscriptions.map((subscriptions) => (
                    <IonSelectOption
                      key={subscriptions.id}
                      value={subscriptions.id}
                    >
                      {subscriptions.name}
                    </IonSelectOption>
                  ))}
              </IonSelect>
            </IonItem>
          )}
          <IonItem onClick={() => setShowFromCalendarModal(true)}>
            <IonLabel>Valido dal</IonLabel>
            <div>
              {/* Mostra la data selezionata o un trattino se non selezionata */}
              {fromDate.toLocaleDateString()}
            </div>

            {/* Modale per la selezione della data */}
          </IonItem>
          <IonModal
            isOpen={showFromCalendarModal}
            onDidDismiss={() => setShowFromCalendarModal(false)}
          >
            <IonDatetime
              className="EditUserDatePicker"
              presentation="date"
              value={fromDate.toISOString()}
              onIonChange={
                // handleDateChange
                (e) => {
                  setTemporarySubscriptions({
                    ...temporarySubscriptions,
                    start_date: new Date(e.detail.value),
                  });
                }
              }
            />
            <IonButton onClick={() => setShowFromCalendarModal(false)}>
              Chiudi
            </IonButton>
          </IonModal>
          <IonItem onClick={() => setShowToCalendarModal(true)}>
            <IonLabel>Valido al</IonLabel>
            <div>
              {/* Mostra la data selezionata o un trattino se non selezionata */}
              {toDate.toLocaleDateString()}
            </div>

            {/* Modale per la selezione della data */}
          </IonItem>
          <IonModal
            isOpen={showToCalendarModal}
            onDidDismiss={() => setShowToCalendarModal(false)}
          >
            <IonDatetime
              className="EditUserDatePicker"
              presentation="date"
              value={toDate.toISOString()}
              onIonChange={
                // handleDateChange
                (e) => {
                  setTemporarySubscriptions({
                    ...temporarySubscriptions,
                    end_date: new Date(e.detail.value),
                  });
                }
              }
            />
            <IonButton onClick={() => setShowToCalendarModal(false)}>
              Chiudi
            </IonButton>
          </IonModal>
          <IonItem>
            <IonCheckbox
              className="ion-text-right"
              label="Pagato"
              name="pagato"
              checked={paid}
              justify="space-between"
              type="checkbox"
              placeholder="Descrizione"
              onIonChange={(e) => {
                const newObject = {
                  ...temporarySubscriptions,
                  paid: e.detail.checked,
                };

                if (!e.detail.checked) {
                  newObject.payment_date = null;
                }

                setTemporarySubscriptions(newObject);
              }}
            >
              <IonLabel>Pagato</IonLabel>
            </IonCheckbox>
          </IonItem>
          <IonItem onClick={() => setShowPaymentDateModal(true)}>
            <IonLabel>Data pagamento</IonLabel>
            <div>{paymentDate ? paymentDate.toLocaleDateString() : "-"}</div>

            {/* Modale per la selezione della data */}
          </IonItem>
          <IonModal
            isOpen={showPaymentDateModal}
            onDidDismiss={() => setShowPaymentDateModal(false)}
          >
            <IonDatetime
              className="EditUserDatePicker"
              presentation="date"
              value={paymentDate ? paymentDate.toISOString() : ""}
              onIonChange={
                // handleDateChange
                (e) => {
                  debugger;
                  setTemporarySubscriptions({
                    ...temporarySubscriptions,
                    payment_date: new Date(e.detail.value),
                  });
                }
              }
            />
            <IonButton onClick={() => setShowPaymentDateModal(false)}>
              Chiudi
            </IonButton>
          </IonModal>
          <IonItem>
            <IonInput
              className="ion-text-right"
              label="Tipo pagamento"
              name="payment"
              value={payment_channel}
              placeholder="Tipo pagamento"
            ></IonInput>
          </IonItem>
          <IonItem>
            <IonTextarea
              className="ion-text-right"
              label="Note"
              name="note"
              value={payment_note}
              placeholder="Note"
            ></IonTextarea>
          </IonItem>
        </IonList>
      </IonContent>
    </IonModal>
  );
}

SubscriptionEditModal.propTypes = {
  hideSelectedAthletes: PropTypes.bool,
  isOpen: PropTypes.bool,
  athletes: PropTypes.array,
  selectedAthletes: PropTypes.array,
  onDismiss: PropTypes.func,
  onConfirm: PropTypes.func,
  subscription: PropTypes.shape({
    id: PropTypes.string,
    start_date: PropTypes.shape({ seconds: PropTypes.number }),
    end_date: PropTypes.shape({ seconds: PropTypes.number }),
    participants: PropTypes.array,
    fieldName: PropTypes.string,
    clubName: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    paid: PropTypes.bool,
    payment_date: PropTypes.shape({ seconds: PropTypes.number }),
    payment_channel: PropTypes.string,
  }),
};

export default SubscriptionEditModal;
