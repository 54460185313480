import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import useSubscriptionsByUserAndCoach from "../../../../hooks/subscriptions/useSubscriptionsByUserAndCoach";
import SubscriptionCard from "./SubscriptionCard";
import {
  IonActionSheet,
  IonButton,
  IonIcon,
  IonRefresher,
  IonRefresherContent,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";
import SubscriptionEditModal from "../../../../components/SubscriptionEditModal/SubscriptionEditModal";
import CardListHeader from "../../../../components/CardListHeader/CardListHeader";

function SubscriptionsContent({
  coachId,
  userId,
  showHeader = true,
  canAddSubscription = true,
  defaultFilterValue = "Non Pagati",
}) {
  const { data, error, isLoading, refreshData } =
    useSubscriptionsByUserAndCoach(coachId, userId);
  const selectRef = useRef(null);

  const handleRefresh = (e) => {
    refreshData();
    e.detail.complete();
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [selectedFilterValue, setSelectedFilterValue] =
    useState(defaultFilterValue);

  const filteredData = data?.filter((subscription) => {
    if (selectedFilterValue === "Non Pagati") {
      return subscription.paid === false;
    }
    return true;
  });

  return (
    <div>
      <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>
      {showHeader && (
        <CardListHeader
          filterOptions={{
            defaultSelectedOption: selectedFilterValue,
            options: ["Tutti", "Non Pagati"],
          }}
          canAddSubscription={canAddSubscription}
          onAddClick={() => {
            if (!coachId) return;
            setSelectedSubscription(null);
            setIsModalOpen(true);
          }}
          onFilterChange={(option) => {
            setSelectedFilterValue(option);
          }}
        />
      )}
      {filteredData &&
        filteredData.map((subscription) => (
          <div
            key={subscription.id}
            onClick={() => {
              setSelectedSubscription(subscription);
              setIsModalOpen(true);
            }}
          >
            <SubscriptionCard key={subscription.id} data={subscription} />
          </div>
        ))}
      {isModalOpen && coachId && (
        <SubscriptionEditModal
          isOpen={isModalOpen}
          subscription={selectedSubscription}
          userId={userId}
          onDismiss={() => setIsModalOpen(false)}
          onConfirm={() => {
            setIsModalOpen(false);
            refreshData();
          }}
        />
      )}
    </div>
  );
}

SubscriptionsContent.propTypes = {
  canAddSubscription: PropTypes.bool,
  coachId: PropTypes.string.isRequired,
  showHeader: PropTypes.bool,
  userId: PropTypes.string.isRequired,
};

export default SubscriptionsContent;
