import React from "react";
import PropTypes from "prop-types";
import { IonChip, IonIcon, IonLabel } from "@ionic/react";
import { closeCircle } from "ionicons/icons";

function SubscriptionList({ subscriptions, onRemoveClick }) {
  const onIconClick = (e, subscription) => {
    onRemoveClick(e, subscription);
  };
  return (
    <div className="subscription-list">
      {subscriptions.map((subscription) => (
        <IonChip key={subscription.id}>
          <IonLabel>{subscription.name}</IonLabel>
          <IonIcon
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onIconClick(e, subscription);
            }}
            icon={closeCircle}
          ></IonIcon>
        </IonChip>
      ))}
    </div>
  );
}

SubscriptionList.propTypes = {
  subscriptions: PropTypes.array,
  onRemoveClick: PropTypes.func,
};

export default SubscriptionList;
