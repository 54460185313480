import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  IonBadge,
  IonButton,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonIcon,
  IonText,
} from "@ionic/react";
import { calendarOutline, cardOutline, personOutline } from "ionicons/icons";

import "./styles/GroupLogCard.scss";
import { FormattedDate } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import useLessonsByGroupLogId from "../../../../hooks/lessons/useLessonsByGroupLogId";

function isSubscriptionActive(from_date, to_date) {
  const now = new Date();
  return (
    now >= new Date(from_date.seconds * 1000) &&
    now <= new Date(to_date.seconds * 1000)
  );
}

function GroupLogCard({ data, className }) {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const {
    data: lessonByPaymentData,
    error: lessonError,
    isLoading: isLoadingLessonData,
  } = useLessonsByGroupLogId(data.id, !isDetailsOpen);
  const {
    id,
    from_date,
    to_date,
    description,
    paid,
    payment_date,
    payment_channel,
    full_name,
  } = data;

  return (
    <IonCard
      key={id}
      style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
      className={className ? `GroupLogCard ${className}` : "GroupLogCard"}
    >
      <IonCardContent style={{ flex: 1, paddingLeft: "1rem" }}>
        <IonText>
          <h5>
            <IonIcon icon={calendarOutline}></IonIcon>
            <FormattedDate
              value={from_date.seconds * 1000}
              day="2-digit"
              month="2-digit"
              year="2-digit"
            />
            &nbsp;-&nbsp;
            <FormattedDate
              value={to_date.seconds * 1000}
              day="2-digit"
              month="2-digit"
              year="2-digit"
            />
          </h5>
          {isSubscriptionActive(from_date, to_date) && (
            <IonBadge color="success">Attivo</IonBadge>
          )}
          {!isSubscriptionActive(from_date, to_date) && (
            <IonBadge color="primary">Non attivo</IonBadge>
          )}
        </IonText>
        <IonCardTitle>{description}</IonCardTitle>
        <IonText>
          <h5>
            <IonIcon icon={personOutline}></IonIcon>
            {full_name}
          </h5>
        </IonText>
        <IonText>
          <h5>
            <IonIcon icon={cardOutline}></IonIcon>
            {paid ? (
              <span style={{ color: "green" }}>
                {payment_date ? (
                  <>
                    Pagato il&nbsp;
                    <FormattedDate
                      value={payment_date.seconds * 1000}
                      day="2-digit"
                      month="2-digit"
                      year="2-digit"
                    />
                  </>
                ) : null}
                {payment_channel && <>&nbsp;tramite {payment_channel}</>}
              </span>
            ) : (
              <span style={{ color: "red" }}>Non pagato</span>
            )}
          </h5>
        </IonText>
      </IonCardContent>
      <IonCardContent
        style={{
          flex: "0 1 auto",
          paddingLeft: "1rem",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <IonButton
          fill="clear"
          onClick={(e) => {
            e.stopPropagation();
            setIsDetailsOpen(!isDetailsOpen);
          }}
        >
          <FontAwesomeIcon
            icon={faChevronUp}
            style={{
              fontSize: "1.5em",
            }}
          />
        </IonButton>
      </IonCardContent>
      {isDetailsOpen && (
        <IonCardContent
          className="GroupLogCard__details"
          style={{
            flexBasis: "100%",
          }}
        >
          <h2>Diario lezioni</h2>
          <div key={data.id} className="GroupLogCard__lessons">
            <h5>
              {data.description}
              &nbsp;-&nbsp;(
              <FormattedDate
                value={data.from_date.seconds * 1000}
                day="2-digit"
                month="2-digit"
                year="2-digit"
              />
              &nbsp;-&nbsp;
              <FormattedDate
                value={data.to_date.seconds * 1000}
                day="2-digit"
                month="2-digit"
                year="2-digit"
              />
              )
            </h5>
            {data.lessons < data.minimum_lessons ? (
              <IonBadge color="danger">Lezioni da recuperare</IonBadge>
            ) : (
              ""
            )}
            <h6>
              Lezioni svolte{" "}
              <span className="GroupLogCard__lesson_number">
                {data.lessons || "-"}
              </span>{" "}
              su{" "}
              <span className="GroupLogCard__lesson_number">
                {data.minimum_lessons}
              </span>{" "}
              pianificate
            </h6>
            <ul>
              {(lessonByPaymentData || []).map((lesson) => (
                <li key={lesson.id}>
                  <FormattedDate
                    value={lesson.to_date.seconds * 1000}
                    day="2-digit"
                    month="2-digit"
                    year="2-digit"
                    weekday="long"
                  />
                </li>
              ))}
            </ul>
          </div>
        </IonCardContent>
      )}
    </IonCard>
  );
}

GroupLogCard.propTypes = {
  href: PropTypes.string,
  imageUrl: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.string,
    from_date: PropTypes.shape({ seconds: PropTypes.number }),
    full_name: PropTypes.string,
    to_date: PropTypes.shape({ seconds: PropTypes.number }),
    participants: PropTypes.array,
    fieldName: PropTypes.string,
    clubName: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    paid: PropTypes.bool,
    payment_date: PropTypes.shape({ seconds: PropTypes.number }),
    payment_channel: PropTypes.string,
    lessonList: PropTypes.arrayOf(PropTypes.object),
    minimum_lessons: PropTypes.number,
    lessons: PropTypes.number,
  }),
};

export default GroupLogCard;
