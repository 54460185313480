import { useContext, useEffect } from "react";
import useFirestore from "../basic/useFirestore";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { FirebaseContext } from "../../providers/FirebaseProvider/FirebaseProvider";

function useSubscriptionsByUserAndCoach(coachId, userId, preventCall) {
  const { firestoreDb } = useContext(FirebaseContext);
  const firestoreQueryMethod = async () => {
    const conditions = [];
    if (userId) {
      conditions.push(where("user_id", "==", userId));
    }
    if (coachId) {
      conditions.push(where("supervisor_id", "==", coachId));
    }
    return getDocs(
      query(
        collection(firestoreDb, "payment_logs"),
        ...conditions,
        orderBy("end_date", "desc"),
      ),
    );
  };

  const parsingFuntion = (data) => {
    return data.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
  };

  const { data, error, isLoading, refreshData } = useFirestore(
    firestoreQueryMethod,
    parsingFuntion,
    preventCall,
  );

  useEffect(() => {
    if (preventCall) return;
    refreshData();
  }, [coachId, userId]);

  return {
    data,
    error,
    isLoading,
    refreshData,
  };
}

export default useSubscriptionsByUserAndCoach;
